html,
body {
  overflow: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #282c34;
}

/* * {
  box-sizing: border-box;
} */

.App {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  /* height: 100vh;
  width: 100vw; */

  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* border: solid 4px white; */
}

.check-list{
  max-height: 400px;
  overflow-y: scroll;
}

/* .App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
/* .App-header {
  align-items: center;
} */

/* .App {
  text-align: center;
} */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* CSS specific to iOS devices */
/* @supports (-webkit-touch-callout: none) {
  .App-header {
    min-height: 88vh;
  }
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
